@import '/src/styles/palette.module.scss';

.container {
  margin: 30px 0;
  padding-bottom: 40px;
  border-bottom: solid 1px $text;
}

.icon {
  color: $cta-secondary
}

.header {
  display: flex;
  align-items: center;
  font-size: 24px;
}

.name {
  color: $cta;
}

.chip {
  margin: 10px 0 20px 0;
}

.desc {
  font-size: 18px;
  max-width: 800px;
}

.technologies {
  display: flex;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap;

  .tech {
    transition: all 0.3s ease;
    width: 40px;
    padding: 10px;
    margin: 0 5px;
    border-radius: 10px;

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 0 10px $cta;
    }
  }

}