.container {
  margin: 20px;
}

.header {
  @media(min-width: 600px) {
    margin: 0 10px;
  }
}

.entries {
  margin-top: 30px;
}