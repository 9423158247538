@import '/src/styles/palette.module.scss';

.container {
  font-size: 32px;
  display: flex;
  align-items: center;
}

.underlined {
  border-bottom: solid 1px $text;
}

.filterSelect {
  color: white;
  width: 200px;
}

.cloudyText {
  margin: 20px 0;
  font-size: 2em;
  background: linear-gradient(270deg, #79addc, #ffc09f, #ffee93, #fcf5c7, #adf7b6, #79addc, #ffc09f, #ffee93, #fcf5c7, #adf7b6, #79addc);
  background-size: 400% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: colorClouds 15s linear infinite;

  @media(min-width: 600px) {
    font-size: 2.75rem;
  }
}

@keyframes colorClouds {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}