@import '/src/styles/palette.module.scss';

.container {
  height: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media(min-width: 500px) {
    margin-top: 0;
  }
  
  @media(min-height: 550px) {
    height: 100vh;
  }
}

.icons {
  width: 200px;
  margin: 30px auto;
}

.cta {
  text-align: center;

  @media(min-height: 750px) {
    margin-top: 5vh;
  }
}

.text,
.title {
  color: $text;
  font-size: 1.2em;

  @media(min-width: 500px) {
    font-size: 1.8rem;
  }
}

.cloudyText {
  margin: 20px 0;
  font-size: 1.75em;
  background: linear-gradient(270deg, #79addc, #ffc09f, #ffee93, #fcf5c7, #adf7b6, #79addc, #ffc09f, #ffee93, #fcf5c7, #adf7b6, #79addc);
  background-size: 400% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: colorClouds 15s linear infinite;

  @media(min-width: 500px) {
    font-size: 2.75rem;
  }
}

@keyframes colorClouds {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}


