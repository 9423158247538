@import '/src/styles/palette.module.scss';

.container {
  border-bottom: solid 1px white;
  padding-bottom: 30px;
  margin: 20px 15px;
}

.company {
  font-size: 1.5rem;
  color: $cta;
}

.roles {
  margin-top: 20px;
}

.line {
  height: 20px;
  border-left: solid 2px $text;
  margin: 10px 0 10px 2px;
}

.title {
  font-weight: bold;
  font-size: 1.1rem;
}

.actions {
  margin-top: 20px;
}

ul {
  padding-left: 15px;
  max-width: 800px;
}
