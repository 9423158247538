@import '/src/styles/palette.module.scss';

.container {
  margin: 0 10px;
  
  @media(min-width: 460px) {
    margin: 0 20px;
  }
}

.header {
  @media(min-width: 600px) {
    margin: 0 10px;
    
  }
}

.posts {
  display: flex;
  flex-wrap: wrap;
}

.noPosts {
  color: $text-secondary;
  font-size: 18px;
  text-align: center;

  @media(min-width: 500px) {
    font-size: 24px;
    margin: 15px; 
  }

  .checkBack {
    margin-top: 10px;
  }
}