.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.icon { 
  width: 30px;
  margin: 10px;
}