@import '/src/styles/palette.module.scss';

.container {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.text {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 300px);

  h1 {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  @media(min-width: 400px) {
    justify-content: center;
    font-size: 1.3rem;
    align-items: center;
  }
}

.wording {
  display: none;
  
  @media(min-width: 600px) {
    display: inline;
  }
}

.emailAddress {
  text-transform: initial;
  
  @media(min-width: 600px) {
    font-size: 1.3rem;
    display: inline;
  }
}

.linkedin {
  text-transform: initial;

  @media(min-width: 600px) {
    font-size: 1.3rem;
    display: inline;
  }
}

.email {
  position: relative;
}

.header {
  @media(min-width: 600px) {
    margin: 0 10px;
    
  }
}

.socialsContainer {
  display: flex;
  justify-content: center;
}

.copied {
  display: none;
  font-size: 0.8rem;
  
  @media(min-width: 600px) {
    display: inline;
    margin-left: 5px;
    position: absolute;
    top: 10px;
  }
}

.hide {
  display: none;
}

.show {
  @media(min-width: 600px) {
    display: inline;
  }
}