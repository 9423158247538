.container {
  display: flex;
  align-items: center;
  margin-top: 50px;
  
  @media(min-height: 600px ) {    
    position: absolute;
    bottom: 20px;
    margin-top: 0;
  }
}

.icon {
  font-size: 2rem;
  margin: 0 10px;
  border-radius: 50%;
  color: white;
}